import "./style";
import { Component, render } from "preact";
import {
  Center,
  ChakraProvider,
  Flex,
  HStack,
  Image,
  extendTheme,
  Box,
  Text,
  IconButton,
  Icon,
  Link
} from "@chakra-ui/react";

import { FaPinterestP, FaInstagram, FaFacebookF } from "react-icons/fa"

const theme = extendTheme({
  colors: {
    brand: {
      primary: "#193321",
      bg: "#a2c3b5"
    },
    ui: {
      container: "rgba(210, 223, 219, 0.45)",
      button: "rgba(210, 223, 219, 0.45)"
      // button: "#a2c3b5"
    }
  }
});

export default class App extends Component {
  render() {
    return (
      <ChakraProvider theme={theme}>
        <Flex flexDirection="column" minHeight="100vh" bgImage="url('./assets/bg-boats-blended.jpg')" bgPosition="top center" bgSize="cover">
          <Box w="100%" minHeight="50px" backgroundColor="brand.bg">
            <Image src="./assets/logo-transparent.png" maxHeight="50px" p="1" pl="3" />
          </Box>

          <Center flex="1" minHeight="50" />
          
          <Center mb="5vh">
            <Box backgroundColor="ui.container" boxShadow="md" padding="1.5em 4em" maxWidth="70vw">
              <Center>
                <Image src="./assets/hero-text.png"></Image>
                {/* <Text width="70%" height="80%" textTransform="uppercase" textAlign="justify" color="brand.primary" fontWeight="semibold">
                  <Text fontSize="150%">Experiences</Text> designed by people who like experiences
                </Text> */}
              </Center>
            </Box>
          </Center>

          <Center mb="5vh">
            {/* <HStack spacing="1.5em" backgroundColor="ui.container" boxShadow="md" padding="1em"> */}
            <HStack spacing="1.5em" padding="1em">
              <Link href="https://www.instagram.com/zero.bit.media/" isExternal><IconButton size="lg" aria-label="View on Instagram" icon={<Icon as={FaInstagram} />} backgroundColor="ui.button" boxShadow="md" /></Link>
              <Link href="https://www.facebook.com/zerobitmedia" isExternal><IconButton size="lg" aria-label="View on Facebook" icon={<Icon as={FaFacebookF} />} backgroundColor="ui.button" boxShadow="md" /></Link>
              <Link href="https://www.pinterest.com/ZeroBitMedia" isExternal><IconButton size="lg"  aria-label="View on Pinterest" icon={<Icon as={FaPinterestP} />} backgroundColor="ui.button" boxShadow="md" /></Link>
            </HStack>
          </Center>
        </Flex>
      </ChakraProvider>
    );
  }
}

if (typeof window !== "undefined") {
  render(<App />, document.body);
}
